// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2022-2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import { Col, Row } from 'antd/lib/grid';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import Icon from '@ant-design/icons';
import {
    BackArrowIcon, ClearIcon,
} from 'icons';

import CVATSigningInput, { CVATInputType } from 'components/signing-common/cvat-signing-input';
import { CombinedState } from 'reducers';
import { usePlugins } from 'utils/hooks';

export interface LoginData {
    credential: string;
    email: string;
    password: string;
}

interface Props {
    renderResetPassword: boolean;
    fetching: boolean;
    onSubmit(loginData: LoginData): void;
}

function LoginFormComponent(props: Props): JSX.Element {
    const {
        fetching, onSubmit, renderResetPassword,
    } = props;
    const [form] = Form.useForm();
    const [credential, setCredential] = useState('');
    const [email, setEmail] = useState('');
    const pluginsToRender = usePlugins(
        (state: CombinedState) => state.plugins.components.loginPage.loginForm,
        props, { credential },
    );

    const forgotPasswordLink = (
        <Col className='cvat-credentials-link'>
            <Text strong>
                <Link to={credential.includes('@') ?
                    `/auth/password/reset?credential=${credential}` : '/auth/password/reset'}
                >
                    Forgot password?
                </Link>
            </Text>
        </Col>
    );

    return (
        <div className='cvat-login-form-wrapper'>
            <Row justify='space-between' className='cvat-credentials-navigation'>
                {
                    credential && (
                        <Col>
                            <Icon
                                component={BackArrowIcon}
                                onClick={() => {
                                    setCredential('');
                                    form.setFieldsValue({ credential: '' });
                                }}
                            />
                        </Col>
                    )
                }
                {/* {
                    !credential && (
                        <Row>
                            <Col className='cvat-credentials-link'>
                                <Text strong>
                                    New user?&nbsp;
                                    <Link to='/auth/register'>Create an account</Link>
                                </Text>
                            </Col>
                        </Row>
                    )
                }
                {
                    renderResetPassword && forgotPasswordLink
                } */}
            </Row>
            <Col>
                <Title level={2}> Sign in </Title>
            </Col>
            <Form
                className={`cvat-login-form ${(credential && email) ? 'cvat-login-form-extended' : ''}`}
                form={form}
                onFinish={(loginData: LoginData) => {
                    onSubmit(loginData);
                }}
            >
                <Form.Item
                    className='cvat-credentials-form-item'
                    name='credential'
                >
                    <Input
                        autoComplete='credential'
                        prefix={<Text>Username</Text>}
                        className={credential ? 'cvat-input-floating-label-above' : 'cvat-input-floating-label'}
                        suffix={credential && (
                            <Icon
                                component={ClearIcon}
                                onClick={() => {
                                    setCredential('');
                                    form.setFieldsValue({ credential: '', email: '', password: '' });
                                }}
                            />
                        )}
                        onChange={(event) => {
                            const { value } = event.target;
                            setCredential(value);
                            if (!value) form.setFieldsValue({ credential: '', email: '', password: '' });
                        }}
                    />
                </Form.Item>
                <Form.Item
                    className='cvat-credentials-form-item'
                    name='email'
                >
                <Input
                        autoComplete='email'
                        prefix={<Text>Email</Text>}
                        className={email ? 'cvat-input-floating-label-above' : 'cvat-input-floating-label'}
                        suffix={email && (
                            <Icon
                                component={ClearIcon}
                                onClick={() => {
                                    setEmail('');
                                    form.setFieldsValue({ credential: '', email: '', password: '' });
                                }}
                            />
                        )}
                        onChange={(event) => {
                            const { value } = event.target;
                            setEmail(value);
                            if (!value) form.setFieldsValue({ credential: '', email: '', password: '' });
                        }}
                    />
                </Form.Item>
                {
                    (credential && email) && (
                        <Form.Item
                            className='cvat-credentials-form-item'
                            name='password'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please specify a password',
                                },
                            ]}
                        >
                            <CVATSigningInput
                                type={CVATInputType.PASSWORD}
                                id='password'
                                placeholder='Password'
                                autoComplete='password'
                            />
                        </Form.Item>
                    )
                }
                {
                    !!(credential && email) && (
                        <Form.Item>
                            <Button
                                className='cvat-credentials-action-button'
                                loading={fetching}
                                disabled={!(credential && email)}
                                htmlType='submit'
                            >
                                Next
                            </Button>
                        </Form.Item>
                    )
                }
                {
                    pluginsToRender.map(({ component: Component }, index) => (
                        <Component targetProps={props} targetState={{ credential }} key={index} />
                    ))
                }
            </Form>
        </div>
    );
}

export default React.memo(LoginFormComponent);
